<template>
    <div>
        <div id="app-md-calendario" :key = "$store.state.keys.calendario">
            <div id="calendar-main-wrap">
				<BookingCreate />

                <div class="md-layout md-calendario-toolbar md-gutter md-alignment-center-space-between ">
                    <div class="md-layout-item md-size-40">
                        <md-datepicker v-model="calendar_date" md-immediately :readonly="true" :md-debounce="100"/>
                    </div>
                    <div class="md-layout-item md-size-60 calendar-toolbar-icons" style="" :key="$store.state.keys.counters">
                        <md-button class="md-icon-button" @click="reload">
                            <md-icon>refresh</md-icon>
                        </md-button>

                        <md-button class="md-icon-button" @click="showCategories = true">
                            <md-icon>filter_alt</md-icon>
                        </md-button>
                        <md-badge class="md-primary "
								  v-if="!is_empty($store.state.badge_channel_creadas)"
								  :md-content="$store.state.badge_channel_creadas" md-dense>
                            <md-button class="md-icon-button" :to="{name:'nuevas_hoy'}">
                                <md-icon>event_available</md-icon>
                            </md-button>
                        </md-badge>

                        <md-badge class="md-danger"
								  v-if="!is_empty($store.state.badge_channel_canceladas)"
								  :md-content="$store.state.badge_channel_canceladas" md-dense>
                            <md-button class="md-icon-button" :to="{name:'canceladas_hoy'}">
                                <md-icon>event_busy</md-icon>
                            </md-button>
                        </md-badge>
						<md-button  @click="create(true)" class="md-icon-button md-dense md-raised md-primary">
							<md-icon>add</md-icon>
						</md-button>
                    </div>
                </div>
                <md-drawer class="md-right" :md-active.sync="showCategories">
                    <md-toolbar class="md-transparent" md-elevation="0">
                        <span class="md-title">{{lang.categorias}}</span>
                    </md-toolbar>

                    <md-list>
                        <md-list-item v-for="categoria in categorias " :key="categoria.id_categoria">
                            <md-checkbox v-model="categoriasActivas" :value="categoria.id_categoria">
                                {{categoria.nombre}}
                            </md-checkbox>
                        </md-list-item>
                    </md-list>
                </md-drawer>

                <div id="calendar-wrapper" >
                    <div id="calendar-header-wrap">
                        <table id="calendar-header"></table>
                    </div>
                    <div id="calendar-calendario" @click="manageCalendarClicks">
                        <div id="calendar-left-wrap">
                            <table id="left-panel"></table>
                        </div>
                        <div id="calendar-wrap">
                            <table id="calendar"></table>
                        </div>
                    </div>
                </div>


                <div class="md-layout  md-gutter md-alignment-center-space-between botom-toolbar">
                    <div class="md-layout-item md-size-20">
                        <md-button @click="moveDays(-7)">
                            <md-icon>arrow_back</md-icon>
                            <small>7 {{lang.dias}}</small>
                        </md-button>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="moveDays(-1)">
                            <md-icon>arrow_back_ios</md-icon>
                            <small>1 {{lang.dia}}</small>
                        </md-button>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="moveDays('hoy')">
                            <md-icon>today</md-icon>
                            <small>{{lang.hoy}}</small>
                        </md-button>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="moveDays(1)">
                            <md-icon>arrow_forward_ios</md-icon>
                            <small>1 {{lang.dia}}</small>
                        </md-button>
                    </div>
                    <div class="md-layout-item md-size-20">
                        <md-button @click="moveDays(7)">
                            <md-icon>arrow_forward</md-icon>
                            <small>7 {{lang.dias}}</small>
                        </md-button>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog-confirm
                :md-active.sync="$store.state.show_modal_timmer"
                :md-title="lang.inactive_calendar"
                :md-content="lang.inactive_calendar_message"
                :md-confirm-text="lang.aceptar"
                :md-cancel-text="lang.cancelar"
                @md-cancel="cancel_refresh()"
                @md-confirm="confirm_refresh()" />
    </div>
</template>
<script>
import {empty} from "../../helpers";
import BookingCreate from "./BookingCreate";

export default {
        name: "app-md-calendario",
		components: {BookingCreate},
		data: function(){
			return {
				showCategories : false,
                refresh_modal : true,
				booking: {
					show:false,
					data: {}
				},
				debounce : false
			}
		},
        computed : {
        	lang(){return this.$store.state.lang},
			categorias(){return this.$store.state.categorias},
			calendar_date:{
        		get(){
					return this.$store.state.calendarDate;
				},
				set(n){
					this.BookingCreate = false;
					if(this.debounce) {
						this.debounce = false;
						App.vue.$store.commit('setCalendarDate', n);
						App.lobbyCalendar.date = n;
						App.lobbyCalendar.render();
					}else{
						this.debounce = true;
					}

				}
			},
            categoriasActivas : {
                get(){
                    return this.$store.state.categoriasActivas;
                },
                set(n){
                    this.$store.commit('setCategoriasActivas',n);
                    App.lobbyCalendar.set_categorias_acativas(n)
                }
            }

        },
        methods: {
            cancel_refresh(){
                this.refresh_modal = false
            },
            confirm_refresh(){

                this.reload();
            },
        	create(clear = false){
        		if(clear){
					this.$store.state.create_data={
						date: null,
						tipo : '',
						categoria :'',
						cuarto : ''
					}
				}

        		this.$store.state.showBookingCreate = true;
        		//this.$router.push({name:'calendar.create'})
			},
        	is_empty(val){
				let boolean =  empty(val)
				return (boolean || val == '0');
			},
            manageCalendarClicks(e){

                e.preventDefault();
                e.stopPropagation();

                let reserva  = e.target.getAttribute('data-booking-id');
                let bloquedo = (' ' + e.target.className + ' ').indexOf(' bloqueado ');

                if(bloquedo > -1){
                    this.$router.push({ name: 'calendar.lock.edit', params: { id: reserva }});
                    return
                }

                if(!empty(reserva)){
                    this.$router.push({path:`/Reserva/${reserva}/detalle`});

                }else if(bloquedo == -1){
					let room  = e.target.getAttribute('data-room-index');
					let date = e.target.getAttribute('data-date');
					if(!empty(room)){
						room = room.split('-');
						this.$store.state.create_data = {
							date: date,
							tipo : room[0],
							categoria: room[1],
							cuarto : room[2]
						};
						this.create();
					}
				}

            },
			reload(){
        		App.lobbyCalendar.render();
			},
            moveDays(days){
        		if(days == 'hoy'){
					App.lobbyCalendar.date = null;
					this.$store.state.calendarDate = null;
					App.lobbyCalendar.render();
                }else {
                    let date = moment((this.calendar_date)?? new Date());
                    date = date.add(days, 'days');
                    this.$store.state.calendarDate = new Date(date.toDate());
                }
            }
        },
		async beforeCreate() {
			this.$store.state.showBookingCreate = false;
			this.$store.state.create_data={
				date: null,
				tipo : '',
				categoria :'',
				cuarto : ''
			}
		},
		mounted: function (){
            let vue = this;
            swipedetect(document.getElementById('app-md-calendario'), function(swipedir){
                if (swipedir != 'none'){
                    if(swipedir == 'left') vue.moveDays(7) ;
                    if(swipedir == 'right') vue.moveDays(-7);
                }

            });


            this.$nextTick(function () {
				//this.$store.dispatch('getServerData', {lg: this.$store.state.active_lang})
                this.$store.commit('setTitle', this.lang.calendario);
                this.$store.state.loading = false;
                if(!this.calendar_date){
					App.lobbyCalendar.render();
				}
            });

        },
    }
</script>

<style lang="scss" scoped>
	.calendar-toolbar-icons{
		display: flex; justify-content: flex-end;

		.md-icon-button {
			width: 30px;
			min-width: 30px;
			height: 30px;
			margin: 0 6px;
		}

	}
	.md-badge.d-none {
		display: none;
	}

    #calendar-main-wrap, #app-md-calendario {
        position: relative;
    }

</style>
