<template>
	<md-dialog :md-active.sync="$store.state.showBookingCreate" :md-fullscreen="false">

		<md-dialog-title v-if="!selected">
			<md-icon>add</md-icon> {{lang.agregar_nuevo}}
		</md-dialog-title>
		<md-dialog-title v-else style="font-size: 1rem">
			<md-icon>today</md-icon> {{selected.date}} | {{selected.tipo}}<br>
			<md-icon>bed</md-icon> {{selected.habitacion}} - {{selected.categoria}}
		</md-dialog-title>
		<md-list class="md-double-line">
			<md-divider></md-divider>
			<md-list-item class="btn-list" to="/create">
				<md-icon class="md-size-2x">bed</md-icon>
				<div class="md-list-item-text" >
					<span>{{lang.reserva.toUpperCase()}}</span>
					<span>{{lang.crear_reserva}}</span>
				</div>
			</md-list-item>
			<md-divider></md-divider>
			<md-list-item class="btn-list" to="/lock">
				<md-icon class="md-size-2x">lock</md-icon>
				<div class="md-list-item-text">
					<span>{{lang.bloqueo.toUpperCase()}}</span>
					<span>{{lang.bloquear_habitacion}}</span>
				</div>
			</md-list-item>
			<md-divider></md-divider>
		</md-list>
		<md-dialog-actions>
			<md-button class="md-primary" @click="$store.state.showBookingCreate = false">{{lang.cerrar}}</md-button>
		</md-dialog-actions>
	</md-dialog>
</template>

<script>
	import moment from 'moment';
	export default {
		name: "BookingCreate",
		computed : {
			lang() {return this.$store.state.lang},
			selected(){
				if(this.$store.state.create_data.date){
					let create_data = this.$store.state.create_data;
					return  {
						date : moment(create_data.date).format('DD/MM/YYYY'),
						tipo : this.$store.getters.habitaciones[create_data.tipo].tipo,
						categoria : this.$store.getters.habitaciones[create_data.tipo].categorias[create_data.categoria].nombre,
						habitacion : this.$store.getters.habitaciones[create_data.tipo].categorias[create_data.categoria].habitaciones[create_data.cuarto].nombre,
					}
				}
				return false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.md-list-item-content>.md-icon:first-child {
		margin-right: 13px;
	}

</style>
